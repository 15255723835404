import React, { Component } from 'react'
import styled from 'styled-components'

const NativePDF = styled.object`
  height: 100vh;
  width: 100%;
`
class PDF extends Component {
  render() {
    return <NativePDF data="Wright_JP_CV.pdf" type="application/pdf" />
  }
}
export default PDF
